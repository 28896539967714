import React from "react";
import "./Services.css";
import placeholder_image from "../assets/placeholder.jpeg";

const Services = () => {
  return (
    <div className="container-fluid services" style={{background: "#eef1ef"}} id="services">
      <div className="row justify-content-center">
        <div className="col-lg-9 mb-5">
          <h2>OUR SERVICES</h2>
          <h3>Efficient financial management</h3>
          <p>
            We handle your finances from invoices to balance sheets, all
            automated for your convenience.
          </p>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-lg-9 col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="card border-0">
                <img
                  className="card-img-top"
                  src={placeholder_image}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">Automated accounting</h5>
                  <p className="card-text">Effortlessly manage your finances</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0">
                <img
                  className="card-img-top"
                  src={placeholder_image}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">Expense tracking</h5>
                  <p className="card-text">Track your expenses effortlessly</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0">
                <img
                  className="card-img-top"
                  src={placeholder_image}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">Financial reporting</h5>
                  <p className="card-text">
                    Generate detailed financial reports
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0 border-radius-0">
                <img
                  className="card-img-top"
                  src={placeholder_image}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">Invoice Management</h5>
                  <p className="card-text">Effortlessly handle your invoices</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0">
                <img
                  className="card-img-top"
                  src={placeholder_image}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">Budget Planning</h5>
                  <p className="card-text">Plan your budget with ease</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card border-0">
                <img
                  className="card-img-top"
                  src={placeholder_image}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <h5 className="card-title">Financial advice</h5>
                  <p className="card-text">Get expert financial guid ance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
