import React from "react";
import backgroundImage from "../assets/BannerImage.jpg";
import "./HeroSection.css";
import { HashLink } from "react-router-hash-link";

const HeroSection = () => {
  return (
    <div className="parallax-container" id="lander">
      <div
        className="overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      ></div>
      <div
        className="parallax-image"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <div className="container content-container d-flex align-items-center justify-content-start">
        <div className="text-left">
          <h1>Automated Accounting Service</h1>
          <p className="lead">Taking care of your finances</p>
          <HashLink to="#services">
            <button
              className="btn"
              style={{
                backgroundColor: "#4CAF50",
                color: "white",
                borderRadius: 0,
                fontWeight: "bold",
              }}
            >
              View Services
            </button>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
