import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { HashLink } from "react-router-hash-link";
import { DiCode } from "react-icons/di";


const Footer = () => {
  return (
    <footer className="bg-dark text-light py-3" style={{ width: "100%", minHeight: "25vh", alignContent: "center" }} id="footer-down">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-4 d-flex justify-content-start align-items-center">
            <ul className="list-unstyled mb-0">
              <li className="d-inline mx-2">
                <HashLink to="#services" className="text-light">
                  Services
                </HashLink>
              </li>
              <li className="d-inline mx-2">
                <HashLink to="#contact-us" className="text-light">
                  Schedule Appointment
                </HashLink>
              </li>
              <li className="d-inline mx-2">
                <Link to="/intake" className="text-light">
                  Complete Intake
                </Link>
              </li>
              <li className="d-inline mx-2">
                <HashLink to="#about-us" className="text-light">
                  Contact
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="col-md-1"> </div>
          <div className="col-md-4 d-flex justify-content-end align-items-center">
            <a href="https://github.com/sndev28" style={{color: "white", textDecoration: "none"}}><p className="mb-0">Web design by Shamil <DiCode />
</p></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
