import React from "react";
import "./ContactUs.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { CiMail } from "react-icons/ci";

const ContactUs = () => {
  return (
    <div className="container-fluid my-5 contact-us justify-content-center" id="contact-us">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-3">
          <h2>GET IN TOUCH</h2>
          <h3>Contact us for financial solutions</h3>
          <p>
            Reach out to NumerInsight for automated accounting services tailored
            to your needs.
          </p>
          <form>
            <div className="mb-3" style={{ borderRadius: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Name *"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email address *"
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="tel"
                className="form-control"
                placeholder="Phone number *"
                required
              />
            </div>
            <div className="mb-3">
              <textarea
                className="form-control"
                rows="3"
                placeholder="Message"
              ></textarea>
            </div>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" />
              <label className="form-check-label">
                I allow this website to store my submission so they can respond
                to my inquiry.
              </label>
            </div>
            <div className="mb-3">
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-3 contact-info-container p-0">
          <div className="contact-info">
            <div class="map-placeholder">
              <div class="map-placeholder__notice">
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 18c-.9 0-1.5-.6-1.5-1.5S11.1 15 12 15s1.5.6 1.5 1.5S12.9 18 12 18zm-1.5-4.5h3V6h-3v7.5z"
                    fill="#fff"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <p>
                  We are unable to show a map because the location is not full
                  or incorrect. Map not found.
                </p>
                <p>
                  Note: this message will not show on the actual completed website.
                </p>
              </div>
            </div>
            <div className="inner-contact-info">
              <h5>Get in touch</h5>
              <p>
                <a href="mailto:sales@numerinsight.com" style={{color: "#666", textDecoration: "none"}}>
                  {" "}
                  <CiMail /> sales@numerinsight.com
                </a>
              </p>
              <h5>Location</h5>
              <p>
                {" "}
                <FaMapMarkerAlt /> Malappuram, KL IN
              </p>
              <h5>Hours</h5>
              <div
                style={{
                  lineHeight: "0.75rem",
                }}
              >
                <p>Monday: 9:00am - 10:00pm</p>
                <p>Tuesday: 9:00am - 10:00pm</p>
                <p>Wednesday: 9:00am - 10:00pm</p>
                <p>Thursday: 9:00am - 10:00pm</p>
                <p>Friday: 9:00am - 10:00pm</p>
                <p>Saturday: 9:00am - 10:00pm</p>
                <p>Sunday: 9:00am - 12:00pm</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
