import React from 'react';
import HeroSection from './HeroSection/HeroSection';
import Navbar from './Navbar/Navbar';
import AboutUs from './AboutUs/AboutUs';
import Services from './Services/Services';
import ContactUs from './ContactUs/ContactUs';
import Footer from './Footer/Footer';

function Homepage() {
  return (
    <div className="container-fluid m-0 p-0">

      <Navbar />

      <HeroSection />

      <AboutUs />

      <Services />

      <ContactUs />

      <Footer />
      
    </div>
  );
}

export default Homepage;
