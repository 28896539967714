import React from "react";
import aboutImage from "../assets/about-image.jpeg";
import "./AboutUs.css";
import { HashLink } from "react-router-hash-link";


const AboutUs = () => {
  return (
    <div className="container-fluid my-20 about-us" id="about-us">
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-4 col-md-12">
          <h2>ABOUT US</h2>
          <h3>Your financial partner in Malappuram</h3>
          <p>
            NumerInsight in Malappuram offers automated accounting services,
            managing invoices, transactions, and providing financial advice.
          </p>
          <HashLink to="#contact-us">
            <button
              className="btn btn-link p-0"
              style={{ textDecoration: "underline", color: "black" }}
            >
              Get in touch
            </button>
          </HashLink>
        </div>
        <div className="col-lg-1 col-md-12"></div>
        <div className="col-lg-4 col-md-12 sb-text-image__image">
          <figure
            data-bg={`url(${aboutImage})`}
            className="option-image"
            skip-data-sb=""
            style={{ backgroundImage: `url(${aboutImage})` }}
            data-was-processed="true"
          >
            <img loading="lazy" src={aboutImage} />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
